import {useFormikContext} from 'formik'
import {ItemUpdateRequestForm} from './ItemUpdateRequest'
import {WorkflowSelector} from '../model/WorkflowSelector'
import {ServiceEnum} from '../../model/ServiceEnum'
import React from 'react'
import {ServiceRequestStep} from '../genericComponents/formTemplate/steps/ServiceRequestStep'
import {Label} from '../../../genericComponents/typographie/Label'
import {Link} from '@mui/material'
import * as FileSaver from 'file-saver'

export const ReviewAndSubmitItemUpdate = () => {
    const formik = useFormikContext<ItemUpdateRequestForm>()
    const getFile = () => {
        const file = formik?.values?.file
        if (!file) return
        FileSaver.saveAs(file, file.name)
    }

    return <ServiceRequestStep>
        <div className="service-request-form-summary" data-testid="service-request-form-summary">
            <Label className="form-summary-label">Data Domain</Label>
            <span className="form-summary-value" data-testid="displaySet-value">
                {formik?.values?.dataDomain?.label ?? '-'}
            </span>
            <Label className="form-summary-label">Description</Label>
            <span className="form-summary-value"
                  data-testid="description-value">{formik?.values?.description ?? '-'}</span>
            <Label className="form-summary-label">Supporting Document</Label>
            {formik?.values?.file?.name
                ? <Link onClick={getFile} className="form-summary-value link" data-testid="download-template">
                    {formik?.values?.file?.name ?? '-'}
                </Link>
                : <span className="form-summary-value">-</span>}

        </div>
        <WorkflowSelector service={ServiceEnum.ITEM_UPDATE}/>
    </ServiceRequestStep>
}