import {searchAPI} from '../search/rtkSearchApi'
import {DataDomain} from './DataDomain'

export const DATA_DOMAINS_TAG = 'DataDomain'

export const dataDomainApi = searchAPI.enhanceEndpoints({
    addTagTypes: [DATA_DOMAINS_TAG]
}).injectEndpoints({
    endpoints: (builder) => ({
        getAllDataDomains: builder.query<DataDomain[], void>({
            query: () => ({
                url: 'datadomains',
                method: 'GET'
            }),
            transformErrorResponse: () => 'Something went wrong during data domains retrieval',
            providesTags: [DATA_DOMAINS_TAG],
            keepUnusedDataFor: 300
        })
    })
})

export const {useGetAllDataDomainsQuery} = dataDomainApi


